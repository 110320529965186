.immpessContainer {
    width: 100%;
}

.impresslandsale {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    background-color: #d2f0fa;
}

.impressItem {
    position: relative;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}

.impressImg {
    width: 400px;
    height: 300px;
    object-fit: cover;
    display:block;
	overflow: hidden;
    transition: all .3s ease;
}

.impressImg:hover {
    transform: scale(1.3);
}

.impressDa {
    margin-top: 10px;
    background-color: #FFFFE0;
    color: black;
    width: 400px;
    height: 180px;
}

.impressTitle {
    padding-top: 10px;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.impressBtn{
    margin-left: 150px;
    margin-bottom: 30px;
    border:none;
    background-color: goldenrod;
    color: white;
    width: 100px;
    border-radius: 3px;
    height: 35px;
}

.impressBtn:hover {
    background-color: #1F6E04;
    color: goldenrod;
    border-radius: 3px;
    width: 100px;
    height: 35px;
}

.title {
    color: goldenrod;
    padding-top: 20px;
    padding-bottom: 30px;
    text-align: center;
}

.btnImpress {
    margin-top: 20px;
    margin-bottom: 25px;
    width: 150px;
    height: 40px;
    border-radius: 3px;
    background-color: #EEE8AA;
    color: #1F6E04;
    border: 1px solid #1F6E04;
    margin-left: 550px;
}

.btnImpress:hover {
    background-color: #1F6E04;
    color: white;
    border-radius: 3px;
    width: 150px;
    height: 40px;
}

.parnertTitle {
    color: goldenrod;
    font-size: 40px;
    margin-left: 40px;
    padding-top: 24px;
    font-weight: 500;
}

.slideImgPartner{ 
    margin-top: 20px;
    margin-left: 90px;
}

.carousel-image {
    width: 250px;
    height: 250px;
}

.linkimpress {
    text-decoration: none;
    color: #1F6E04;
    font-weight: 500;
}

.nameimpress {
    width: 100%;
    height: 60%;
}

.slideImgPartner > button {
    padding-right: 10%;
}

/* Dành cho máy tính bảng*/
@media all and (max-width: 1024px) {
    .immpessContainer {
       width: 100%;
    }
    .impressImg {
        width: 300px;
        height: 200px;
    }
    .impressDa {
        width: 300px;
        height: 280px;
    }
    .impressBtn {
       margin-left: 35%;
    }
    .btnImpress {
        margin-left: 44%;
    }
    .partnerImpress {
        width: 100%;
    }
}

/* Dành cho máy tính bảng chiều ngang */
@media all and (max-width: 800px) {
     .immpessContainer {
       width: 100%;
    }
    .impressImg {
        width: 236px;
        height: 200px;
    }
    .impressDa {
        width: 236px;
        height: 200px;
        text-align: left;
    }
    .impressBtn {
       margin-left: 30%;
    }
    .btnImpress {
        margin-left: 40%;
    }
    .partnerImpress {
        width: 100%;
    }
    .impressTitle {
        font-size: 16px;
        margin-left: 0%;
    }
    .nameimpress {
        width: 236px;
    }
}

@media all and (max-width: 768px) {
    .immpessContainer {
        width: 100%;
     }
     .impressImg {
         width: 236px;
         height: 200px;
     }
     .impressDa {
         width: 236px;
         height: 200px;
         text-align: left;
     }
     .impressBtn {
        margin-left: 29%;
     }
     .btnImpress {
         margin-left: 40%;
     }
     .partnerImpress {
         width: 100%;
     }
     .impressTitle {
         font-size: 16px;
         margin-left: 0%;
     }
     .nameimpress {
         width: 236px;
     }
}

/* Dành cho máy tính bảng chiều dọc*/
@media all and (max-width: 600px) {
    .immpessContainer {
        width: 100%;
     }
     .impressImg {
         width: 170px;
         height: 200px;
     }
     .impressDa {
         width: 170px;
         height: 200px;
         text-align: left;
     }
     .impressBtn {
        margin-left: 20%;
     }
     .btnImpress {
         margin-left: 40%;
     }
     .partnerImpress {
         width: 100%;
         height: 450px;
     }
     .impressTitle {
         font-size: 16px;
         margin-left: 0%;
     }
     .nameimpress {
         width: 170px;
     }
     .carousel-image {
        width: 200px;
        height: 200px;
     }
}

/* Dành cho điện thoại chiều ngang */
@media all and (max-width: 480px) { 
    .immpessContainer {
        width: 100%;
     }
     .impresslandsale {
        display: block;
        width: 100%;
     }
     .impressImg {
         width: 100%;
         height: 200px;
     }
     .impressItem {
        width: 100%;
        height: 430px;
        padding-left: 10%;
        padding-right: 10%;
     }
     .impressDa {
         width: 100%;
         height: 200px;
     }
     .impressBtn {
        margin-left: 40%;
        width: 80px;
        height: 30px;
     }
     .btnImpress {
         margin-left: 30%;
         height: 30px;
     }
     .impressTitle {
         font-size: 18px;
         width: 100%;
     }
     .nameimpress {
         width: 100%;
     }

     .linkimpress {
        font-size: 14px;
     }
     .partnerImpress {
        width: 100%;
        height: 350px;
     }
     .slideImgPartner{ 
        margin-top: 30px;
        margin-left: 5%;
    }
    .carousel-image {
        width: 150px;
        height: 150px;
    }
    .impressBtn:hover {
        width: 80px;
        height: 30px;
    }
    .btnImpress:hover {
        width: 150px;
        height: 30px;
    }
    .title {
        font-size: 24px;
    }   
    .parnertTitle {
        font-size: 24px;
    } 
}

@media all and (max-width: 414px) {
    .immpessContainer {
        width: 100%;
     }
     .impresslandsale {
        display: block;
        width: 100%;
     }
     .impressImg {
         width: 100%;
         height: 200px;
     }
     .impressItem {
        width: 100%;
        height: 430px;
        padding-left: 10%;
        padding-right: 10%;
     }
     .impressDa {
         width: 100%;
         height: 200px;
     }
     .impressBtn {
        margin-left: 40%;
        width: 80px;
        height: 30px;
     }
     .btnImpress {
         margin-left: 30%;
         height: 30px;
     }
     .impressTitle {
         font-size: 18px;
         width: 100%;
     }
     .nameimpress {
         width: 100%;
     }

     .linkimpress {
        font-size: 14px;
     }
     .partnerImpress {
        width: 100%;
        height: 350px;
     }
     .slideImgPartner{ 
        margin-top: 30px;
        padding-left: 10%;
        padding-right: 12%;
    }
    .carousel-image {
        width: 150px;
        height: 150px;
    }
    .impressBtn:hover {
        width: 80px;
        height: 30px;
    }
    .btnImpress:hover {
        width: 150px;
        height: 30px;
    }
    .title {
        font-size: 24px;
    }   
    .parnertTitle {
        font-size: 24px;
    } 
}


/* Dành cho điện thoại */
@media all and (max-width: 360px) {
    .immpessContainer {
        width: 100%;
     }
     .impresslandsale {
        display: block;
        width: 100%;
     }
     .impressImg {
         width: 100%;
         height: 200px;
     }
     .impressItem {
        width: 100%;
        height: 430px;
        padding-left: 10%;
        padding-right: 10%;
     }
     .impressDa {
         width: 100%;
         height: 200px;
     }
     .impressBtn {
        margin-left: 40%;
        width: 80px;
        height: 30px;
     }
     .btnImpress {
         margin-left: 30%;
         height: 30px;
     }
     .impressTitle {
         font-size: 18px;
         width: 100%;
     }
     .nameimpress {
         width: 100%;
     }

     .linkimpress {
        font-size: 14px;
     }
     .partnerImpress {
        width: 100%;
        height: 350px;
     }
     .slideImgPartner{ 
        margin-top: 30px;
        padding-left: 10%;
        padding-right: 12%;
    }
    .carousel-image {
        width: 150px;
        height: 150px;
    }
    .impressBtn:hover {
        width: 80px;
        height: 30px;
    }
    .btnImpress:hover {
        width: 150px;
        height: 30px;
    }
    .title {
        font-size: 24px;
    }   
    .parnertTitle {
        font-size: 24px;
    } 
}

/* Dành cho điện thoại */
@media all and (max-width: 320px) {
    .immpessContainer {
        width: 320px;
     }
     .impresslandsale {
        display: block;
        padding-left: 1%;
     }
     .impressImg {
         width: 280px;
         height: 170px;
     }
     .impressItem {
        width: 280px;
        height: 380px;
        
     }
     .impressDa {
         width: 280px;
         height: 170px;
     }
     .impressBtn {
        margin-left: 35%;
        width: 80px;
        height: 30px;
     }
     .btnImpress {
         margin-left: 23%;
         height: 30px;
     }
     .impressTitle {
         font-size: 18px;
         width: 280px;
     }
     .nameimpress {
         width: 280px;
     }

     .linkimpress {
        font-size: 14px;
     }
     .partnerImpress {
        width: 295px;
        height: 280px;
     }
     .slideImgPartner{ 
        margin-top: 30px;
        margin-left: 1%;
    }
    .carousel-image {
        width: 100px;
        height: 100px;
        margin-left: 35%;
    }
    .impressBtn:hover {
        width: 80px;
        height: 30px;
    }
    .btnImpress:hover {
        width: 150px;
        height: 30px;
    }
    .title {
        font-size: 24px;
    }   
    .parnertTitle {
        font-size: 24px;
    } 
}

@media all and (min-width: 1280px) and (max-width: 1366px) {
   .immpessContainer {
        width: 100%;
    }
   .partnerImpress {
        width: 100%;
    }
}

@media all and (min-width: 1366px) and (max-width: 1440px) {
    .immpessContainer {
        width: 100%;
    }
    .partnerImpress {
        width: 100%;
    }
    .btnImpress {
        margin-left: 45%;
    }
}

@media all and (min-width: 1440px) and (max-width: 1680px) {
    .immpessContainer {
        width: 100%;
    }
    .partnerImpress {
        width: 100%;
    }
    .btnImpress {
        margin-left: 45%;
    }
}

@media all and (min-width: 1680px) and (max-width: 1920px) {
    .immpessContainer {
        width: 100%;
    }
    .partnerImpress {
        width: 100%;
    }
    .btnImpress {
        margin-left: 45%;
    }
}

@media all and (min-width: 1920px) and (max-width: 2560px) {
    .immpessContainer {
        width: 100%;
    }
    .partnerImpress {
        width: 100%;
    }
    .btnImpress {
        margin-left: 45%;
    }
}