body {
   background-color: white;
}

.register {
    display: flex;
    width: 100%;
    background-image: url(../signup/bgsignup.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.rightlogo {
   width: 60%;
}

.logowww {
    width: 90%;
    margin-left: 10%;
    margin-top: 4%;
}

.welcomewww {
    width: 90%;
    margin-left: 10%;
    margin-top: 4%;
}

.imglogosn {
    width: 50%;
    height: 300px;
}

.textwelcome {
    text-transform: uppercase;
    font-size: 50px;
    color: goldenrod;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.registerForm {
    font-family: Arial, sans-serif;
    background-image: url(../../components/featured/img/bannwe3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 40%;
    margin-top: 2%;
    margin-bottom: 2%;
    padding: 40px;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 10px;
    color: #45a049;
}

.registerForm h2 {
    text-align: center;
    color: #45a049;
}

.registerForm form {
    display: flex;
    flex-direction: column;
}

.registerForm label {
    margin-bottom: 10px;
}

.registerForm input[type="text"],
.registerForm input[type="password"] {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
}

.registerForm input[type="submit"] {
    padding: 10px;
    background-color: #4CAF50; /* Màu nền của nút submit */
    color: white; /* Màu chữ của nút submit */
    border-radius: 5px;
    border: none;
    cursor: pointer;
    
}

.registerForm input[type="submit"]:hover {
    background-color: #45a049; /* Màu nền khi di chuột qua nút submit */
}

.socialLogin button {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.socialLogin button img {
    width: 20px; /* Điều chỉnh kích thước logo */
    margin-right: 5px; /* Thêm khoảng cách giữa logo và văn bản */
}


.loginLink {
    text-align: center;
    margin-top: 20px;
    color: #333;
}

.passwordContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

.passwordContainer input {
    padding-right: 40px; /* Space for the eye icon */
}

.eyeIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
}

@media all and (max-width: 360px) {
    .register {
        display: block;
    }
    .rightlogo {
        width: 100%;
    }
    .logowww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .welcomewww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .imglogosn {
        width: 50%;
        height: 300px;
    }
    
    .textwelcome {
        text-transform: uppercase;
        font-size: 50px;
        color: goldenrod;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
    .registerForm {
        width: 100%;
        margin-top: 1%;
        margin-bottom: 2%;
        padding: 10px;
        background-color: white;
        box-shadow: none;
        border-radius: 0px;
        color: #45a049;
    }
    
    .registerForm h2 {
        text-align: center;
        color: #45a049;
    }
    
    .registerForm form {
        display: flex;
        flex-direction: column;
    }
    
    .registerForm label {
        margin-bottom: 10px;
    }
    
    .registerForm input[type="text"],
    .registerForm input[type="password"] {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
    }
    
    .registerForm input[type="submit"] {
        padding: 5px;
        border-radius: 5px;
    }   
    
    .socialLogin button {
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    
    .socialLogin button img {
        width: 20px; /* Điều chỉnh kích thước logo */
        margin-right: 5px; /* Thêm khoảng cách giữa logo và văn bản */
    }
    
    .passwordContainer {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    
    .passwordContainer input {
        padding-right: 40px; /* Space for the eye icon */
    }
    
    .eyeIcon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
    .rightlogo {
        display: flex;
        width: 100%;
     }
     
     .logowww {
        width: 40%;
     }
     
     .welcomewww {
        width: 60%;
     }
     
     .imglogosn {
         width: 100%;
         height: 60%;
     }
     
     .textwelcome {
         font-size: 22px;
         font-weight: bold;
     }
}

@media all and (max-width: 375px) {
    .register {
        display: block;
    }
    .rightlogo {
        width: 100%;
    }
    .logowww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .welcomewww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .imglogosn {
        width: 50%;
        height: 300px;
    }
    
    .textwelcome {
        text-transform: uppercase;
        font-size: 50px;
        color: goldenrod;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
    .registerForm {
        width: 100%;
        margin-top: 1%;
        margin-bottom: 2%;
        padding: 10px;
        background-color: white;
        box-shadow: none;
        border-radius: 0px;
        color: #45a049;
    }
    
    .registerForm h2 {
        text-align: center;
        color: #45a049;
    }
    
    .registerForm form {
        display: flex;
        flex-direction: column;
    }
    
    .registerForm label {
        margin-bottom: 10px;
    }
    
    .registerForm input[type="text"],
    .registerForm input[type="password"] {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
    }
    
    .registerForm input[type="submit"] {
        padding: 5px;
        border-radius: 5px;
    }   
    
    .socialLogin button {
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    
    .socialLogin button img {
        width: 20px; /* Điều chỉnh kích thước logo */
        margin-right: 5px; /* Thêm khoảng cách giữa logo và văn bản */
    }
    
    .passwordContainer {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    
    .passwordContainer input {
        padding-right: 40px; /* Space for the eye icon */
    }
    
    .eyeIcon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
    .rightlogo {
        display: flex;
        width: 100%;
     }
     
     .logowww {
        width: 40%;
     }
     
     .welcomewww {
        width: 60%;
     }
     
     .imglogosn {
         width: 100%;
         height: 60%;
     }
     
     .textwelcome {
         font-size: 22px;
         font-weight: bold;
     }
}

@media all and (max-width: 414px) {
    .register {
        display: block;
    }
    .rightlogo {
        width: 100%;
    }
    .logowww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .welcomewww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .imglogosn {
        width: 50%;
        height: 300px;
    }
    
    .textwelcome {
        text-transform: uppercase;
        font-size: 50px;
        color: goldenrod;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
    .registerForm {
        width: 100%;
        margin-top: 1%;
        margin-bottom: 2%;
        padding: 10px;
        background-color: white;
        box-shadow: none;
        border-radius: 0px;
        color: #45a049;
    }
    
    .registerForm h2 {
        text-align: center;
        color: #45a049;
    }
    
    .registerForm form {
        display: flex;
        flex-direction: column;
    }
    
    .registerForm label {
        margin-bottom: 10px;
    }
    
    .registerForm input[type="text"],
    .registerForm input[type="password"] {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
    }
    
    .registerForm input[type="submit"] {
        padding: 5px;
        border-radius: 5px;
    }   
    
    .socialLogin button {
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    
    .socialLogin button img {
        width: 20px; /* Điều chỉnh kích thước logo */
        margin-right: 5px; /* Thêm khoảng cách giữa logo và văn bản */
    }
    
    .passwordContainer {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    
    .passwordContainer input {
        padding-right: 40px; /* Space for the eye icon */
    }
    
    .eyeIcon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
    .rightlogo {
        display: flex;
        width: 100%;
     }
     
     .logowww {
        width: 40%;
     }
     
     .welcomewww {
        width: 60%;
     }
     
     .imglogosn {
         width: 100%;
         height: 60%;
     }
     
     .textwelcome {
         font-size: 22px;
         font-weight: bold;
     }
}

@media all and (max-width: 600px) {
    .register {
        display: block;
    }
    .rightlogo {
        width: 100%;
    }
    .logowww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .welcomewww {
        width: 90%;
        margin-left: 10%;
        margin-top: 4%;
    }
    
    .imglogosn {
        width: 50%;
        height: 300px;
    }
    
    .textwelcome {
        text-transform: uppercase;
        font-size: 50px;
        color: goldenrod;
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }
    .registerForm {
        width: 100%;
        margin-top: 1%;
        margin-bottom: 2%;
        padding: 10px;
        background-color: white;
        box-shadow: none;
        border-radius: 0px;
        color: #45a049;
    }
    
    .registerForm h2 {
        text-align: center;
        color: #45a049;
    }
    
    .registerForm form {
        display: flex;
        flex-direction: column;
    }
    
    .registerForm label {
        margin-bottom: 10px;
    }
    
    .registerForm input[type="text"],
    .registerForm input[type="password"] {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
    }
    
    .registerForm input[type="submit"] {
        padding: 5px;
        border-radius: 5px;
    }   
    
    .socialLogin button {
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        border: none;
        cursor: pointer;
    }
    
    .socialLogin button img {
        width: 20px; /* Điều chỉnh kích thước logo */
        margin-right: 5px; /* Thêm khoảng cách giữa logo và văn bản */
    }
    
    .passwordContainer {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    
    .passwordContainer input {
        padding-right: 40px; /* Space for the eye icon */
    }
    
    .eyeIcon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        width: 25px;
        height: 25px;
    }
    .rightlogo {
        display: flex;
        width: 100%;
     }
     
     .logowww {
        width: 40%;
     }
     
     .welcomewww {
        width: 60%;
     }
     
     .imglogosn {
         width: 100%;
         height: 60%;
     }
     
     .textwelcome {
         font-size: 22px;
         font-weight: bold;
     }
}