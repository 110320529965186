.newContainer {
    background-color: #fbfaea;
    width: 100%;
}

.imgNew {
    margin-top: 5.5%;
    width: 100%;
}

.titleNew {
    text-align: center;
    color: goldenrod;
    font-size: 33px;
    font-weight: 500;
}

.chooseNew {
    margin-top: 30px;
    text-align: center;
}

.chooseNew > button {
    font-weight: 400;
    width: 190px;
    height: 40px;
    color: white;
    background-color: rgb(17, 146, 8);
    border: 2px solid rgb(243, 247, 24);
}

.chooseNew > button:hover {
    background-color: goldenrod;
}

.thitruongNew {
    margin-left: 20px;
    margin-right: 20px;
}

.noidungNew {
    padding-right: 5%;
    padding-left: 5%;
    margin-top: 60px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}

.itemNew {
    width: 300px;
    height: 410px;
}

.texttimeNew {
    padding-left: 10px;
    padding-top: 10px;
}

.imgItemNew {
    width: 100%;
    height: 200px;
    border: 1px solid goldenrod;
}

.textItemNew {
    height: 80px;
    font-size: 12rem;
}

.imgItemNew:hover {
    transform: scale(1.3);
}
.coverItemNew {
    background-color: azure;
    height: 180px;
    border: 1px solid goldenrod;
}

.textItemNew {
    font-size: 16px;
    padding-top: 10px;
    color: green;
    font-weight: 500;
    padding-left: 10px;
}

.desItemNew {
    margin-top: 20px;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    text-align: center;
}

.linknew {
    text-decoration: none;
    color: white;
}

.linknewdetail {
    text-decoration: none;
}

.pagination button {
    color: white;
    background-color: green;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination button:active {
    background-color: gray;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
  }
  

/* Dành cho máy tính bảng*/
@media all and (max-width: 1024px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
        width: 100%;
        margin-left: 0px;
        padding-right: 0%;
    }
}

/* Dành cho máy tính bảng chiều ngang */
@media all and (max-width: 800px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
       width: 0%;
       padding-right: 0%;
    }
}

/* Dành cho máy tính bảng loại lớn*/
@media all and (max-width: 768px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
       width: 100%;
       padding-right: 20%;
    }
    .imgItemNew {
        width: 100%;
        height: 200px;
        border: 1px solid goldenrod;
    }
}

/* Dành cho máy tính bảng chiều dọc*/
@media all and (max-width: 600px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
        width: 100%;
        margin-left: 20%;
    }
}

/* Dành cho điện thoại chiều ngang */
@media all and (max-width: 480px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
        width: 100%;
        margin-left: 14%;
    }
}

@media all and (max-width: 414px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
        width: 100%;
        
    }
    .itemNew {
        margin-right: 12%;
    }
    
}

/* Dành cho điện thoại chiều ngang */
@media all and (max-width: 360px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
        width: 100%;
        
    }
    .itemNew {
        margin-right: 12%;
    }
    
}

@media all and (max-width: 320px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
    }
    .noidungNew {
        width: 100%;
        
    }
    .itemNew {
        margin-right: 12%;
    }
}

@media all and (min-width: 1280px) and (max-width: 1366px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
       
    }
}

@media all and (min-width: 1366px) and (max-width: 1440px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
        margin-top: 5%;
       
    }
}

@media all and (min-width: 1440px) and (max-width: 1680px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
       
        margin-top: 4.8%;
    }
}

@media all and (min-width: 1680px) and (max-width: 1920px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
        margin-top: 4.2%;
       
    }
}

@media all and (min-width: 1920px) and (max-width: 2560px) {
    .newContainer {
        width: 100%;
    }
    .imgNew {
        width: 100%;
      
        margin-top: 3.5%;
    }
}