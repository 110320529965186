.payhistoryContainer {
    width: 100%;
    position: absolute;
}

/* CSS cho bảng */
.tabpayhistory {
    width: 100%;
    border-collapse: collapse;
    margin-top: 50px;
    text-align: center;
}

/* CSS cho tiêu đề bảng */
.theadpayhistory {
    background-color: #f2f2f2;
}

.thpayhistory, .tdpayhistory {
  border: 1px solid #ddd;
  padding: 8px;
  
}


/* CSS cho hàng trong bảng */
.trpayhistory {
    border-bottom: 1px solid #ddd;
}

.thpayhistory {
    text-transform: uppercase;
}

.thpayhistory {
  background-color: #4CAF50;
  color: white;
}

/* CSS cho ô trong bảng */
.tdpayhistory {
    padding: 8px;
    text-align: center;
    font-size: 20px;
}

/* CSS cho nút trong bảng */
.btnpayhistory {
    background-color: red; /* Màu nền */
    border: none; /* Không viền */
    color: white; /* Màu chữ */
    padding: 10px; /* Padding */
    text-align: center; /* Căn giữa chữ */
    text-decoration: none; /* Không gạch chân */
    display: inline-block;
    font-size: 18px;
    cursor: pointer; /* Con trỏ chuột */
    border-radius: 5px;
}

/* CSS cho nút khi rê chuột vào */
.btnpayhistory:hover {
    background-color: #45a049;
    color: yellow;
}

.btnpayhistory:active {
  background-color: gray;
}

.tdpayhistory:nth-child(1), .tdpayhistory:nth-child(1) { /* Số thứ tự */
    width: 5%;
  }
  
  .tdpayhistory:nth-child(2), .tdpayhistory:nth-child(2) { /* Số thứ tự */
    width: 10%;
  }

  .tdpayhistory:nth-child(3), .tdpayhistory:nth-child(3) { /* Số thứ tự */
    width: 15%;
  }
  
  .tdpayhistory:nth-child(4), .tdpayhistory:nth-child(4) { /* Số thứ tự */
    width: 10%;
  }

  .tdpayhistory:nth-child(5), .tdpayhistory:nth-child(5) { /* Số thứ tự */
    width: 15%;
  }

  .tdpayhistory:nth-child(6), .tdpayhistory:nth-child(6) { /* Số thứ tự */
    width: 10%;
  }

  .tdpayhistory:nth-child(7), .tdpayhistory:nth-child(7) { /* Số thứ tự */
    width: 15%;
  }

  .tdpayhistory:nth-child(8), .tdpayhistory:nth-child(8) { /* Số thứ tự */
    width: 15%;
  }

 .tdpayhistory:nth-child(9), .tdpayhistory:nth-child(9) { /* Số thứ tự */
    width: 15%;
  }

.success {
    color: green;
}

.cancel {
    color: red;
}

.wascancel {
    color: red;
}

/* CSS cho hàng chẵn trong bảng */
.trpayhistory:nth-child(even) {
  background-color: white;
}

/* CSS cho hàng lẻ trong bảng */
.trpayhistory:nth-child(odd) {
    background-color: wheat;
}

@media all and (max-width: 360px) {

  .tabpayhistory .theadpayhistory .trpayhistory {
    display: none;
  }

  .tabpayhistory .trpayhistory {
    display: block;
  }

  .tabpayhistory .thpayhistory, .tabpayhistory .tdpayhistory {
    padding: .5em;
  }

  .tabpayhistory .tdpayhistory {
    text-align: right;
    display: block;
  }

  .tabpayhistory .tdpayhistory::before {
    content: attr(data-title) " ";
    float: left;
    background-color: #4CAF50;
    padding: 4px;
    color: white;
    border-radius: 4px;
  }

  /* Điều chỉnh độ rộng của các cột */
  .tdpayhistory:nth-child(1), .thpayhistory:nth-child(1) { /* Số thứ tự */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(2), .thpayhistory:nth-child(2) { /* Loại bất động sản */
    width: 100%;
  }

  .tdpayhistory:nth-child(3), .thpayhistory:nth-child(3) { /* Loại bất động sản */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(4), .thpayhistory:nth-child(4) { /* Tên bài đăng */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(5), .thpayhistory:nth-child(5) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(6), .thpayhistory:nth-child(6) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(7), .thpayhistory:nth-child(7) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(8), .thpayhistory:nth-child(8) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(9), .thpayhistory:nth-child(9) { /* Địa chỉ */
    width: 100%;
  }

  .thpayhistory, .tdpayhistory {
    border: none;
    font-size: 13px;
  }

  .btnpayhistory {
    font-size: 14px;
    font-weight: 500;
  }

}

@media all and (max-width: 375px) {

  .tabpayhistory .theadpayhistory .trpayhistory {
    display: none;
  }

  .tabpayhistory .trpayhistory {
    display: block;
  }

  .tabpayhistory .thpayhistory, .tabpayhistory .tdpayhistory {
    padding: .5em;
  }

  .tabpayhistory .tdpayhistory {
    text-align: right;
    display: block;
  }

  .tabpayhistory .tdpayhistory::before {
    content: attr(data-title) " ";
    float: left;
    background-color: #4CAF50;
    padding: 4px;
    color: white;
    border-radius: 4px;
  }

  /* Điều chỉnh độ rộng của các cột */
  .tdpayhistory:nth-child(1), .thpayhistory:nth-child(1) { /* Số thứ tự */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(2), .thpayhistory:nth-child(2) { /* Loại bất động sản */
    width: 100%;
  }

  .tdpayhistory:nth-child(3), .thpayhistory:nth-child(3) { /* Loại bất động sản */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(4), .thpayhistory:nth-child(4) { /* Tên bài đăng */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(5), .thpayhistory:nth-child(5) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(6), .thpayhistory:nth-child(6) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(7), .thpayhistory:nth-child(7) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(8), .thpayhistory:nth-child(8) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(9), .thpayhistory:nth-child(9) { /* Địa chỉ */
    width: 100%;
  }

  .thpayhistory, .tdpayhistory {
    border: none;
    font-size: 13px;
  }

  .btnpayhistory {
    font-size: 14px;
    font-weight: 500;
  }

}

@media all and (max-width: 414px) {

  .tabpayhistory .theadpayhistory .trpayhistory {
    display: none;
  }

  .tabpayhistory .trpayhistory {
    display: block;
  }

  .tabpayhistory .thpayhistory, .tabpayhistory .tdpayhistory {
    padding: .5em;
  }

  .tabpayhistory .tdpayhistory {
    text-align: right;
    display: block;
  }

  .tabpayhistory .tdpayhistory::before {
    content: attr(data-title) " ";
    float: left;
    background-color: #4CAF50;
    padding: 4px;
    color: white;
    border-radius: 4px;
  }

  /* Điều chỉnh độ rộng của các cột */
  .tdpayhistory:nth-child(1), .thpayhistory:nth-child(1) { /* Số thứ tự */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(2), .thpayhistory:nth-child(2) { /* Loại bất động sản */
    width: 100%;
  }

  .tdpayhistory:nth-child(3), .thpayhistory:nth-child(3) { /* Loại bất động sản */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(4), .thpayhistory:nth-child(4) { /* Tên bài đăng */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(5), .thpayhistory:nth-child(5) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(6), .thpayhistory:nth-child(6) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(7), .thpayhistory:nth-child(7) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(8), .thpayhistory:nth-child(8) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(9), .thpayhistory:nth-child(9) { /* Địa chỉ */
    width: 100%;
  }

  .thpayhistory, .tdpayhistory {
    border: none;
    font-size: 13px;
  }

  .btnpayhistory {
    font-size: 14px;
    font-weight: 500;
  }

}

@media all and (max-width: 600px) {

  .tabpayhistory .theadpayhistory .trpayhistory {
    display: none;
  }

  .tabpayhistory .trpayhistory {
    display: block;
  }

  .tabpayhistory .thpayhistory, .tabpayhistory .tdpayhistory {
    padding: .5em;
  }

  .tabpayhistory .tdpayhistory {
    text-align: right;
    display: block;
  }

  .tabpayhistory .tdpayhistory::before {
    content: attr(data-title) " ";
    float: left;
    background-color: #4CAF50;
    padding: 4px;
    color: white;
    border-radius: 4px;
  }

  /* Điều chỉnh độ rộng của các cột */
  .tdpayhistory:nth-child(1), .thpayhistory:nth-child(1) { /* Số thứ tự */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(2), .thpayhistory:nth-child(2) { /* Loại bất động sản */
    width: 100%;
  }

  .tdpayhistory:nth-child(3), .thpayhistory:nth-child(3) { /* Loại bất động sản */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(4), .thpayhistory:nth-child(4) { /* Tên bài đăng */
    width: 100%;
  }
  
  .tdpayhistory:nth-child(5), .thpayhistory:nth-child(5) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(6), .thpayhistory:nth-child(6) { /* Địa chỉ */
    width: 100%;
  }

  .tdpayhistory:nth-child(7), .thpayhistory:nth-child(7) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(8), .thpayhistory:nth-child(8) { /* Địa chỉ */
    width: 100%;
  }
  .tdpayhistory:nth-child(9), .thpayhistory:nth-child(9) { /* Địa chỉ */
    width: 100%;
  }

  .thpayhistory, .tdpayhistory {
    border: none;
    font-size: 15px;
  }

  .btnpayhistory {
    font-size: 16px;
    font-weight: 500;
  }

}

