.ProfileContainer {
    position: absolute;
    width: 100%;
    background-image: url(../profile/tienich.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.profile {
    margin-top: 8%;
    background-image: url(../../components/featured/img/bannwe3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    width: 500px;
    color: #4CAF50;
    margin-left: 30%;
    margin-bottom: 5%;
}

.titleProfile {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #4CAF50;;
    text-transform: uppercase;
}

.formprofile {
    display: flex;
    flex-direction: column;
}

.avatar-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.file-input {
    margin-top: 10px;
}

.formprofile label {
    margin-top: 10px;
    font-weight: bold;
    text-transform: uppercase;
}

.formprofile input[type=text],
.formprofile input[type=email],
.formprofile input[type=password],
.formprofile input[type=tel] {
    margin-top: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}

.submit-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

@media all and (max-width: 360px) {
    .profile {
        margin-top: 24%;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
        border-radius: 10px;
        width: 96%;
        color: #4CAF50;
        margin-left: 2%;
        margin-bottom: 5%;
    }
    
    .titleProfile {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        color: #4CAF50;;
        text-transform: uppercase;
    }
    
    .formprofile {
        display: flex;
        flex-direction: column;
    }
    
    .avatar-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    
    .file-input {
        margin-top: 10px;
    }
    
    .formprofile label {
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .formprofile input[type=text],
    .formprofile input[type=email],
    .formprofile input[type=password],
    .formprofile input[type=tel] {
        margin-top: 5px;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100%;
    }
    
    .submit-btn {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
    }
}

@media all and (max-width: 375px) {
    .profile {
        margin-top: 24%;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
        border-radius: 10px;
        width: 96%;
        color: #4CAF50;
        margin-left: 2%;
        margin-bottom: 5%;
    }
    
    .titleProfile {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        color: #4CAF50;;
        text-transform: uppercase;
    }
    
    .formprofile {
        display: flex;
        flex-direction: column;
    }
    
    .avatar-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    
    .file-input {
        margin-top: 10px;
    }
    
    .formprofile label {
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .formprofile input[type=text],
    .formprofile input[type=email],
    .formprofile input[type=password],
    .formprofile input[type=tel] {
        margin-top: 5px;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100%;
    }
    
    .submit-btn {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
    }
}

@media all and (max-width: 414px) {
    .profile {
        margin-top: 24%;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
        border-radius: 10px;
        width: 96%;
        color: #4CAF50;
        margin-left: 2%;
        margin-bottom: 5%;
    }
    
    .titleProfile {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        color: #4CAF50;;
        text-transform: uppercase;
    }
    
    .formprofile {
        display: flex;
        flex-direction: column;
    }
    
    .avatar-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    
    .file-input {
        margin-top: 10px;
    }
    
    .formprofile label {
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .formprofile input[type=text],
    .formprofile input[type=email],
    .formprofile input[type=password],
    .formprofile input[type=tel] {
        margin-top: 5px;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100%;
    }
    
    .submit-btn {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
    }
}

@media all and (max-width: 600px) {
    .profile {
        margin-top: 24%;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
        border-radius: 10px;
        width: 96%;
        color: #4CAF50;
        margin-left: 2%;
        margin-bottom: 5%;
    }
    
    .titleProfile {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        color: #4CAF50;;
        text-transform: uppercase;
    }
    
    .formprofile {
        display: flex;
        flex-direction: column;
    }
    
    .avatar-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    
    .file-input {
        margin-top: 10px;
    }
    
    .formprofile label {
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .formprofile input[type=text],
    .formprofile input[type=email],
    .formprofile input[type=password],
    .formprofile input[type=tel] {
        margin-top: 5px;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100%;
    }
    
    .submit-btn {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
    }
}

@media all and (max-width: 800px) {
    .profile {
        margin-top: 24%;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20px;
        border-radius: 10px;
        width: 96%;
        color: #4CAF50;
        margin-left: 2%;
        margin-bottom: 5%;
    }
    
    .titleProfile {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
        color: #4CAF50;;
        text-transform: uppercase;
    }
    
    .formprofile {
        display: flex;
        flex-direction: column;
    }
    
    .avatar-upload {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    
    .file-input {
        margin-top: 10px;
    }
    
    .formprofile label {
        font-size: 16px;
        margin-top: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    .formprofile input[type=text],
    .formprofile input[type=email],
    .formprofile input[type=password],
    .formprofile input[type=tel] {
        margin-top: 5px;
        padding: 6px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100%;
    }
    
    .submit-btn {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #4CAF50; /* Green */
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
    }
}