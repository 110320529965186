.loginpage {
  background-image: url(bglogin.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 700px;
  display: flex;
  width: 100%;
}

.loginContainer {
  font-family: Arial, sans-serif;
  background-image: url(../../components/featured/img/bannwe3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 40%;
  padding: 40px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 10px;
  color: #45a049;
  height: 60%;
  margin-left: 5%;
  margin-top: 10%;
}

.loginContainer h2 {
  text-align: center;
  color: #45a049;
}

.loginContainer form {
  display: flex;
  flex-direction: column;
}

.loginContainer label {
  margin-bottom: 10px;
}

.loginContainer input[type="text"],
.loginContainer input[type="password"] {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
}

.loginContainer input[type="submit"] {
  padding: 10px;
  background-color: #4CAF50; /* Màu nền của nút submit */
  color: white; /* Màu chữ của nút submit */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  
}

.loginContainer input[type="submit"]:hover {
  background-color: #45a049; /* Màu nền khi di chuột qua nút submit */
}

.socialLogin button {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.loginLink {
  text-align: center;
  margin-top: 20px;
  color: #333;
}

.passwordContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.passwordContainer input {
  padding-right: 40px; /* Space for the eye icon */
}

.eyeIcon {
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.resetpass {
  color: #e2200e;
  text-decoration: none;
}

.rightlogin {
  width: 50%;
  margin-left: 5%;
  margin-top: 10%;
}

.logologin {
  width: 100%;
}

.imglogin {
  width: 50%;
}

.imglog {
  width: 100%;
  height: 250px;
}

.textloginw {
  width: 100%;
  margin-top: 10%;
}

.wellogin {
  width: 100%;
}

.h2textlogin {
  width: 100%;
  font-size: 40px;
  color: goldenrod;
  font-weight: 600;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@media all and (max-width: 360px) {
  .loginpage {
    display: block;
    width: 100%;
  }
  
  .loginContainer {
    width: 100%;
    padding: 15px;
    height: 50%;
    margin-left: 0%;
    margin-top: 0%;
    font-size: 14px;
  }
  
  .loginContainer input[type="text"],
  .loginContainer input[type="password"] {
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
  
  .loginContainer input[type="submit"] {
    padding: 4px;
    width: 100%;
  }
  
  .loginContainer input[type="submit"]:hover {
    background-color: #45a049; /* Màu nền khi di chuột qua nút submit */
  }
  
  .eyeIcon {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  
  .resetpass {
    color: #e2200e;
    text-decoration: none;
  }
  
  .rightlogin {
    width: 90%;
    display: flex;
  }
  
  .logologin {
    width: 40%;
  }
  
  .imglogin {
    width: 100%;
  }
  
  .imglog {
    width: 100%;
    height: 40%;
  }
  
  .textloginw {
    width: 50%;
    margin-top: 0%;
    margin-left: 5%;
  }
  
  .wellogin {
    width: 100%;
  }
  
  .h2textlogin {
    width: 100%;
    font-size: 30px;
    font-weight: bold;
  }

  
}

@media all and (max-width: 375px) {
  .loginpage {
    display: block;
    width: 100%;
  }
  
  .loginContainer {
    width: 100%;
    padding: 15px;
    height: 50%;
    margin-left: 0%;
    margin-top: 0%;
    font-size: 14px;
  }
  
  .loginContainer input[type="text"],
  .loginContainer input[type="password"] {
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
  
  .loginContainer input[type="submit"] {
    padding: 4px;
    width: 100%;
  }
  
  .loginContainer input[type="submit"]:hover {
    background-color: #45a049; /* Màu nền khi di chuột qua nút submit */
  }
  
  .eyeIcon {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  
  .resetpass {
    color: #e2200e;
    text-decoration: none;
  }
  
  .rightlogin {
    width: 90%;
    display: flex;
  }
  
  .logologin {
    width: 40%;
  }
  
  .imglogin {
    width: 100%;
  }
  
  .imglog {
    width: 100%;
    height: 40%;
  }
  
  .textloginw {
    width: 50%;
    margin-top: 0%;
    margin-left: 5%;
  }
  
  .wellogin {
    width: 100%;
  }
  
  .h2textlogin {
    width: 100%;
    font-size: 30px;
    font-weight: bold;
  }
}

@media all and (max-width: 414px) {
  .loginpage {
    display: block;
    width: 100%;
  }
  
  .loginContainer {
    width: 100%;
    padding: 15px;
    height: 50%;
    margin-left: 0%;
    margin-top: 0%;
    font-size: 14px;
  }
  
  .loginContainer input[type="text"],
  .loginContainer input[type="password"] {
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
  
  .loginContainer input[type="submit"] {
    width: 100%;
    padding: 4px;
  }
  
  .loginContainer input[type="submit"]:hover {
    background-color: #45a049; /* Màu nền khi di chuột qua nút submit */
  }
  
  .eyeIcon {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  
  .resetpass {
    color: #e2200e;
    text-decoration: none;
  }
  
  .rightlogin {
    width: 90%;
    display: flex;
  }
  
  .logologin {
    width: 40%;
  }
  
  .imglogin {
    width: 100%;
  }
  
  .imglog {
    width: 100%;
    height: 40%;
  }
  
  .textloginw {
    width: 50%;
    margin-top: 0%;
    margin-left: 5%;
  }
  
  .wellogin {
    width: 100%;
  }
  
  .h2textlogin {
    width: 100%;
    font-size: 30px;
    font-weight: bold;
  }
}

@media all and (max-width: 600px) {
  .loginpage {
    display: block;
    width: 100%;
  }
  
  .loginContainer {
    width: 100%;
    padding: 15px;
    height: 50%;
    margin-left: 0%;
    margin-top: 0%;
    font-size: 14px;
  }
  
  .loginContainer input[type="text"],
  .loginContainer input[type="password"] {
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
  
  .loginContainer input[type="submit"] {
    width: 100%;
    padding: 4px;
  }
  
  .loginContainer input[type="submit"]:hover {
    background-color: #45a049; /* Màu nền khi di chuột qua nút submit */
  }
  
  .eyeIcon {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  
  .resetpass {
    color: #e2200e;
    text-decoration: none;
  }
  
  .rightlogin {
    width: 90%;
    display: flex;
  }
  
  .logologin {
    width: 40%;
  }
  
  .imglogin {
    width: 100%;
  }
  
  .imglog {
    width: 100%;
    height: 40%;
  }
  
  .textloginw {
    width: 50%;
    margin-top: 0%;
    margin-left: 5%;
  }
  
  .wellogin {
    width: 100%;
  }
  
  .h2textlogin {
    width: 100%;
    font-size: 30px;
    font-weight: bold;
  }
}

@media all and (max-width: 768px) {
  .loginpage {
    display: block;
    width: 100%;
  }
  
  .loginContainer {
    width: 100%;
    padding: 15px;
    height: 50%;
    margin-left: 0%;
    margin-top: 0%;
    font-size: 15px;
  }
  
  .loginContainer input[type="text"],
  .loginContainer input[type="password"] {
    padding: 4px;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
  
  .loginContainer input[type="submit"] {
    width: 100%;
    padding: 4px;
  }
  
  .loginContainer input[type="submit"]:hover {
    background-color: #45a049; /* Màu nền khi di chuột qua nút submit */
  }
  
  .eyeIcon {
    position: absolute;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  
  .resetpass {
    color: #e2200e;
    text-decoration: none;
  }
  
  .rightlogin {
    width: 90%;
    display: flex;
  }
  
  .logologin {
    width: 40%;
  }
  
  .imglogin {
    width: 100%;
  }
  
  .imglog {
    width: 100%;
    height: 40%;
  }
  
  .textloginw {
    width: 50%;
    margin-top: 0%;
    margin-left: 5%;
  }
  
  .wellogin {
    width: 100%;
  }
  
  .h2textlogin {
    width: 100%;
    font-size: 31px;
    font-weight: bold;
  }
}