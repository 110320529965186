.packetContainer {
    background-image: url(bgpackage.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
}

.packagepage {
   margin-top: 3%;
   margin-bottom: 5%;
}

.titlePacket {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: green;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 8%;
    width: 100%;
}

.textgoi {
   background-color: rgb(33, 114, 229);
   color: white;
   border: 2px solid green;
   border-radius: 10px;
   box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
}

.textgoi:hover {
    color: red;
    transform: scale(1.05);
    transition: all .2s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border: 2px solid green;
}

.package-list {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.package-card {
    background-image: url(../../components/featured/img/bannwe3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    width: 350px;
    height: 90vh;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.package-card h2 {
    color: #0872eb;
    font-size: 30px;
    text-transform: uppercase;
    border-bottom: 2px solid #e29e4a; /* Thêm dòng này */
    padding-bottom: 5px;
    font-weight: 500;
}

.package-card p {
    color: #f05143;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
}

.package-card:hover {
    transform: scale(1.05);
    transition: all .2s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border: 2px solid green;
}

.package-card h2:hover {
    color: #ff5722;
    transition: all .2s ease-in-out;
}

.package-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
}


.package-card p:last-child {
    margin-bottom: 20px; 
}

.divppaket{
    line-height: 4; 
}

.package-card p:hover {
    color: green;
}

.package-list {
    align-items: center; 
}

.btnChon {
    border: none;
    background-color: rgb(0, 200, 255);
    color: white;
    padding: 8px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 22px;
    text-transform: uppercase;
}

.package-card .detail {
    color: black; /* Thay đổi màu sắc tại đây */
    text-decoration: underline;
    
}

.backhome {
    width: 10%;
    height: 10%;
    background-color: rgb(13, 134, 240);
    margin-top: 5px;
    margin-left: 20px;
    border: 2px solid green;
    border-radius: 3px;
}

.payPacket {
    text-decoration: none;
    color: white;
}

.payPacket:hover {
    color: yellow;
}

.btnChon:hover {
    background-color: #cced0f;
    color: white;
}

.package-card:nth-child(1)::before {
    content: "HOT";
    color: #ff0000;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
    background-image: url('../packet/star.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10px;
}

/* File CSS */
.descriptionpackage {
    width: 80%;
    margin: auto;
    padding: 20px;
    box-shadow: 10px 5px 15px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-image: url(../packet/banner_resize.png);
    background-repeat: no-repeat;
    background-size: cover;
    font-family: Arial, sans-serif;
    height: 400px;
    margin-top: 3%;
}

.descriptionpackage h2 {
    color: #0872eb;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 3%;
}

.descriptionpackage p {
    color: rgb(210, 153, 7);
    font-size: 20px;
    line-height: 1.6;
    margin-bottom: 10px;
    text-transform: uppercase;
    line-height: 200%;
    font-weight: bold;
    margin-left: 5%;
}

.descriptionpackage .detaildes {
    font-weight: 500;
    color: #333;
}

@media all and (max-width: 360px) {
    .packetContainer {
        width: 100%;
    }
    
    .packagepage {
       margin-top: 3%;
       margin-bottom: 5%;
    }
    
    .titlePacket {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: green;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 28%;
        width: 100%;
    }
    
    .textgoi {
       background-color: rgb(33, 114, 229);
       color: white;
       border: 2px solid green;
       border-radius: 10px;
       box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    }
    
    .textgoi:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-list {
        display: block;
        padding: 0px;
    }
    
    .package-card {
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        border-radius: 5px;
        padding: 20px;
        margin: 10px;
        width: 94%;
        height: 60vh;
        text-align: center;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
    
    .package-card h2 {
        color: #0872eb;
        font-size: 18px;
        text-transform: uppercase;
        border-bottom: 2px solid #e29e4a; /* Thêm dòng này */
        padding-bottom: 5px;
        font-weight: 500;
    }
    
    .package-card p {
        color: #f05143;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }
    
    .package-card:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-card h2:hover {
        color: #ff5722;
        transition: all .2s ease-in-out;
    }
    
    .package-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
    }
    
    
    .package-card p:last-child {
        margin-bottom: 20px; 
    }
    
    .divppaket{
        line-height: 4; 
    }
    
    .package-card p:hover {
        color: green;
    }
    
    .package-list {
        align-items: center; 
    }
    
    .btnChon {
        border: none;
        background-color: rgb(0, 200, 255);
        color: white;
        padding: 4px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .package-card .detail {
        color: black; /* Thay đổi màu sắc tại đây */
        text-decoration: underline;
        
    }
    
    .backhome {
        width: 10%;
        height: 10%;
        background-color: rgb(13, 134, 240);
        margin-top: 5px;
        margin-left: 20px;
        border: 2px solid green;
        border-radius: 3px;
    }
    
    .payPacket {
        text-decoration: none;
        color: white;
    }
    
    .payPacket:hover {
        color: yellow;
    }
    
    .btnChon:hover {
        background-color: #cced0f;
        color: white;
    }
    
    .package-card:nth-child(1)::before {
        content: "HOT";
        color: #ff0000;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url('../packet/star.png'); 
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px;
    }
    
    .descriptionpackage {
        width: 96%;
        margin: auto;
        padding: 10px;
        box-shadow: 10px 5px 15px rgba(0,0,0,0.2);
        border-radius: 5px;
        background-image: url(../packet/banner_resize.png);
        background-repeat: no-repeat;
        background-size: cover;
        font-family: Arial, sans-serif;
        height: 450px;
        margin-top: 3%;
    }
    
    .descriptionpackage h2 {
        color: #0872eb;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 3%;
    }
    
    .descriptionpackage p {
        color: rgb(210, 153, 7);
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 10px;
        text-transform: uppercase;
        line-height: 200%;
        font-weight: bold;
        margin-left: 5%;
    }
    
    .descriptionpackage .detaildes {
        font-weight: 500;
        color: #333;
    }
    
}

@media all and (max-width: 375px) {
    .packetContainer {
        width: 100%;
    }
    
    .packagepage {
       margin-top: 3%;
       margin-bottom: 5%;
    }
    
    .titlePacket {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: green;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 28%;
        width: 100%;
    }
    
    .textgoi {
       background-color: rgb(33, 114, 229);
       color: white;
       border: 2px solid green;
       border-radius: 10px;
       box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    }
    
    .textgoi:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-list {
        display: block;
        padding: 0px;
    }
    
    .package-card {
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        border-radius: 5px;
        padding: 20px;
        margin: 10px;
        width: 94%;
        height: 60vh;
        text-align: center;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
    
    .package-card h2 {
        color: #0872eb;
        font-size: 18px;
        text-transform: uppercase;
        border-bottom: 2px solid #e29e4a; /* Thêm dòng này */
        padding-bottom: 5px;
        font-weight: 500;
    }
    
    .package-card p {
        color: #f05143;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }
    
    .package-card:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-card h2:hover {
        color: #ff5722;
        transition: all .2s ease-in-out;
    }
    
    .package-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
    }
    
    
    .package-card p:last-child {
        margin-bottom: 20px; 
    }
    
    .divppaket{
        line-height: 4; 
    }
    
    .package-card p:hover {
        color: green;
    }
    
    .package-list {
        align-items: center; 
    }
    
    .btnChon {
        border: none;
        background-color: rgb(0, 200, 255);
        color: white;
        padding: 4px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .package-card .detail {
        color: black; /* Thay đổi màu sắc tại đây */
        text-decoration: underline;
        
    }
    
    .backhome {
        width: 10%;
        height: 10%;
        background-color: rgb(13, 134, 240);
        margin-top: 5px;
        margin-left: 20px;
        border: 2px solid green;
        border-radius: 3px;
    }
    
    .payPacket {
        text-decoration: none;
        color: white;
    }
    
    .payPacket:hover {
        color: yellow;
    }
    
    .btnChon:hover {
        background-color: #cced0f;
        color: white;
    }
    
    .package-card:nth-child(1)::before {
        content: "HOT";
        color: #ff0000;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url('../packet/star.png'); 
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px;
    }
    
    .descriptionpackage {
        width: 96%;
        margin: auto;
        padding: 10px;
        box-shadow: 10px 5px 15px rgba(0,0,0,0.2);
        border-radius: 5px;
        background-image: url(../packet/banner_resize.png);
        background-repeat: no-repeat;
        background-size: cover;
        font-family: Arial, sans-serif;
        height: 450px;
        margin-top: 3%;
    }
    
    .descriptionpackage h2 {
        color: #0872eb;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 3%;
    }
    
    .descriptionpackage p {
        color: rgb(210, 153, 7);
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 10px;
        text-transform: uppercase;
        line-height: 200%;
        font-weight: bold;
        margin-left: 5%;
    }
    
    .descriptionpackage .detaildes {
        font-weight: 500;
        color: #333;
    }
    
}

@media all and (max-width: 414px) {
    .packetContainer {
        width: 100%;
    }
    
    .packagepage {
       margin-top: 3%;
       margin-bottom: 5%;
    }
    
    .titlePacket {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: green;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 28%;
        width: 100%;
    }
    
    .textgoi {
       background-color: rgb(33, 114, 229);
       color: white;
       border: 2px solid green;
       border-radius: 10px;
       box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    }
    
    .textgoi:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-list {
        display: block;
        padding: 0px;
    }
    
    .package-card {
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        border-radius: 5px;
        padding: 20px;
        margin: 10px;
        width: 94%;
        height: 60vh;
        text-align: center;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
    
    .package-card h2 {
        color: #0872eb;
        font-size: 18px;
        text-transform: uppercase;
        border-bottom: 2px solid #e29e4a; /* Thêm dòng này */
        padding-bottom: 5px;
        font-weight: 500;
    }
    
    .package-card p {
        color: #f05143;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
    }
    
    .package-card:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-card h2:hover {
        color: #ff5722;
        transition: all .2s ease-in-out;
    }
    
    .package-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
    }
    
    
    .package-card p:last-child {
        margin-bottom: 20px; 
    }
    
    .divppaket{
        line-height: 4; 
    }
    
    .package-card p:hover {
        color: green;
    }
    
    .package-list {
        align-items: center; 
    }
    
    .btnChon {
        border: none;
        background-color: rgb(0, 200, 255);
        color: white;
        padding: 4px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .package-card .detail {
        color: black; /* Thay đổi màu sắc tại đây */
        text-decoration: underline;
        
    }
    
    .backhome {
        width: 10%;
        height: 10%;
        background-color: rgb(13, 134, 240);
        margin-top: 5px;
        margin-left: 20px;
        border: 2px solid green;
        border-radius: 3px;
    }
    
    .payPacket {
        text-decoration: none;
        color: white;
    }
    
    .payPacket:hover {
        color: yellow;
    }
    
    .btnChon:hover {
        background-color: #cced0f;
        color: white;
    }
    
    .package-card:nth-child(1)::before {
        content: "HOT";
        color: #ff0000;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url('../packet/star.png'); 
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px;
    }
    
    .descriptionpackage {
        width: 96%;
        margin: auto;
        padding: 10px;
        box-shadow: 10px 5px 15px rgba(0,0,0,0.2);
        border-radius: 5px;
        background-image: url(../packet/banner_resize.png);
        background-repeat: no-repeat;
        background-size: cover;
        font-family: Arial, sans-serif;
        height: 450px;
        margin-top: 3%;
    }
    
    .descriptionpackage h2 {
        color: #0872eb;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 3%;
    }
    
    .descriptionpackage p {
        color: rgb(210, 153, 7);
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 10px;
        text-transform: uppercase;
        line-height: 200%;
        font-weight: bold;
        margin-left: 5%;
    }
    
    .descriptionpackage .detaildes {
        font-weight: 500;
        color: #333;
    }
    
}

@media all and (max-width: 600px) {
    .packetContainer {
        width: 100%;
    }
    
    .packagepage {
       margin-top: 3%;
       margin-bottom: 5%;
    }
    
    .titlePacket {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 27px;
        color: green;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 28%;
        width: 100%;
    }
    
    .textgoi {
       background-color: rgb(33, 114, 229);
       color: white;
       border: 2px solid green;
       border-radius: 10px;
       box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    }
    
    .textgoi:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-list {
        display: block;
        padding: 0px;
    }
    
    .package-card {
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        border-radius: 5px;
        padding: 20px;
        margin: 10px;
        width: 94%;
        height: 60vh;
        text-align: center;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
    
    .package-card h2 {
        color: #0872eb;
        font-size: 18px;
        text-transform: uppercase;
        border-bottom: 2px solid #e29e4a; /* Thêm dòng này */
        padding-bottom: 5px;
        font-weight: 500;
    }
    
    .package-card p {
        color: #f05143;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
    
    .package-card:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-card h2:hover {
        color: #ff5722;
        transition: all .2s ease-in-out;
    }
    
    .package-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
    }
    
    
    .package-card p:last-child {
        margin-bottom: 20px; 
    }
    
    .divppaket{
        line-height: 4; 
    }
    
    .package-card p:hover {
        color: green;
    }
    
    .package-list {
        align-items: center; 
    }
    
    .btnChon {
        border: none;
        background-color: rgb(0, 200, 255);
        color: white;
        padding: 4px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
    }
    
    .package-card .detail {
        color: black; /* Thay đổi màu sắc tại đây */
        text-decoration: underline;
        
    }
    
    .backhome {
        width: 10%;
        height: 10%;
        background-color: rgb(13, 134, 240);
        margin-top: 5px;
        margin-left: 20px;
        border: 2px solid green;
        border-radius: 3px;
    }
    
    .payPacket {
        text-decoration: none;
        color: white;
    }
    
    .payPacket:hover {
        color: yellow;
    }
    
    .btnChon:hover {
        background-color: #cced0f;
        color: white;
    }
    
    .package-card:nth-child(1)::before {
        content: "HOT";
        color: #ff0000;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url('../packet/star.png'); 
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px;
    }
    
    .descriptionpackage {
        width: 96%;
        margin: auto;
        padding: 10px;
        box-shadow: 10px 5px 15px rgba(0,0,0,0.2);
        border-radius: 5px;
        background-image: url(../packet/banner_resize.png);
        background-repeat: no-repeat;
        background-size: cover;
        font-family: Arial, sans-serif;
        height: 450px;
        margin-top: 3%;
    }
    
    .descriptionpackage h2 {
        color: #0872eb;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 3%;
    }
    
    .descriptionpackage p {
        color: rgb(210, 153, 7);
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 10px;
        text-transform: uppercase;
        line-height: 200%;
        font-weight: bold;
        margin-left: 5%;
    }
    
    .descriptionpackage .detaildes {
        font-weight: 500;
        color: #333;
    }
    
}

@media all and (max-width: 800px) {
    .packetContainer {
        width: 100%;
    }
    
    .packagepage {
       margin-top: 3%;
       margin-bottom: 5%;
    }
    
    .titlePacket {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 27px;
        color: green;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 28%;
        width: 100%;
    }
    
    .textgoi {
       background-color: rgb(33, 114, 229);
       color: white;
       border: 2px solid green;
       border-radius: 10px;
       box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    }
    
    .textgoi:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-list {
        display: block;
        padding: 0px;
    }
    
    .package-card {
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        border: none;
        border-radius: 5px;
        padding: 20px;
        margin: 10px;
        width: 94%;
        height: 60vh;
        text-align: center;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    }
    
    .package-card h2 {
        color: #0872eb;
        font-size: 18px;
        text-transform: uppercase;
        border-bottom: 2px solid #e29e4a; /* Thêm dòng này */
        padding-bottom: 5px;
        font-weight: 500;
    }
    
    .package-card p {
        color: #f05143;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }
    
    .package-card:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
    }
    
    .package-card h2:hover {
        color: #ff5722;
        transition: all .2s ease-in-out;
    }
    
    .package-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between; 
    }
    
    
    .package-card p:last-child {
        margin-bottom: 20px; 
    }
    
    .divppaket{
        line-height: 4; 
    }
    
    .package-card p:hover {
        color: green;
    }
    
    .package-list {
        align-items: center; 
    }
    
    .btnChon {
        border: none;
        background-color: rgb(0, 200, 255);
        color: white;
        padding: 4px;
        border-radius: 5px;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
    }
    
    .package-card .detail {
        color: black; /* Thay đổi màu sắc tại đây */
        text-decoration: underline;
        
    }
    
    .backhome {
        width: 10%;
        height: 10%;
        background-color: rgb(13, 134, 240);
        margin-top: 5px;
        margin-left: 20px;
        border: 2px solid green;
        border-radius: 3px;
    }
    
    .payPacket {
        text-decoration: none;
        color: white;
    }
    
    .payPacket:hover {
        color: yellow;
    }
    
    .btnChon:hover {
        background-color: #cced0f;
        color: white;
    }
    
    .package-card:nth-child(1)::before {
        content: "HOT";
        color: #ff0000;
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url('../packet/star.png'); 
        background-repeat: no-repeat;
        background-size: cover;
        padding: 10px;
    }
    
    .descriptionpackage {
        width: 96%;
        margin: auto;
        padding: 10px;
        box-shadow: 10px 5px 15px rgba(0,0,0,0.2);
        border-radius: 5px;
        background-image: url(../packet/banner_resize.png);
        background-repeat: no-repeat;
        background-size: cover;
        font-family: Arial, sans-serif;
        height: 450px;
        margin-top: 3%;
    }
    
    .descriptionpackage h2 {
        color: #0872eb;
        font-size: 18px;
        text-align: center;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 3%;
    }
    
    .descriptionpackage p {
        color: rgb(210, 153, 7);
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 10px;
        text-transform: uppercase;
        line-height: 200%;
        font-weight: bold;
        margin-left: 5%;
    }
    
    .descriptionpackage .detaildes {
        font-weight: 500;
        color: #333;
    }
    
}