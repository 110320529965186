.searchTopBDS {
    position: absolute;
    top: 20%;
    right: 18%;
    width: 65%;
}

.inputsearchimpress {
    padding: 30px;
    background-color: #898b8b;
    border-radius: 5px;
    opacity: 90%;
}

.inpsearchimpress {
    width: 83%;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-radius: 2px;
}

.btnsearchimpress {
    border: none;
    width: 15%;
    margin-left: 2%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    background-color: rgb(242, 64, 64);
    color: white;
    font-weight: 500;
}

.btnsearchimpress:active {
    background-color: rgb(128, 102, 102);
}

.btnsearchimpress:hover {
    color: #1F6E04;
    background-color: rgb(211, 211, 14);
    font-weight: 500;
}

.selectgroup {
    display: flex;
    margin-top: 10px;
    width: 100%;
}

.selectimpress {
    width: 34%;
}

.imselectkv, .imselectgia, .imselectdt {
    width: 90%;
    padding: 8px;
    border: none;
    border-radius: 3px;
}

.btnresetimpress {
    border: none;
    border-radius: 5px;
    width: 40px;
    font-size: 12px;
    background-color: #1F6E04;
    color: white;
}

.btnresetimpress:hover {
    background-color: #DAA520;
}

.btnresetimpress:active {
    background-color: #898b8b;
}

@media all and (max-width: 360px) {
    .searchTopBDS {
        top: 10%;
        width: 100%;
        right: 0%;
        
    }
    .inputsearchimpress {
        padding: 15px;
    }
    .inpsearchimpress {
        width: 70%;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
    }
    .btnsearchimpress {
        width: 20%;
        margin-left: 2%;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 12px;
        height: 30px;
    }
    .selectgroup {
        display: flex;
        margin-top: 5px;
        width: 100%;
    }
    
    .selectimpress {
        width: 34%;
    }
    
    .imselectkv, .imselectgia, .imselectdt {
        width: 90%;
        padding: 2px;
        border: none;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .btnresetimpress {
        border: none;
        border-radius: 5px;
        width: 40px;
        font-size: 12px;
        background-color: #1F6E04;
        color: white;
    }
}

@media all and (max-width: 375px) {
    .searchTopBDS {
        top: 10%;
        width: 100%;
        right: 0%;
        
    }
    .inputsearchimpress {
        padding: 15px;
    }
    .inpsearchimpress {
        width: 70%;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
    }
    .btnsearchimpress {
        width: 20%;
        margin-left: 2%;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 12px;
        height: 30px;
    }
    .selectgroup {
        display: flex;
        margin-top: 5px;
        width: 100%;
    }
    
    .selectimpress {
        width: 34%;
    }
    
    .imselectkv, .imselectgia, .imselectdt {
        width: 90%;
        padding: 2px;
        border: none;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .btnresetimpress {
        border: none;
        border-radius: 5px;
        width: 40px;
        font-size: 12px;
        background-color: #1F6E04;
        color: white;
    }
}

@media all and (max-width: 414px) {
    .searchTopBDS {
        top: 10%;
        width: 100%;
        right: 0%;
        
    }
    .inputsearchimpress {
        padding: 15px;
    }
    .inpsearchimpress {
        width: 70%;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
    }
    .btnsearchimpress {
        width: 20%;
        margin-left: 2%;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 12px;
        height: 30px;
    }
    .selectgroup {
        display: flex;
        margin-top: 5px;
        width: 100%;
    }
    
    .selectimpress {
        width: 34%;
    }
    
    .imselectkv, .imselectgia, .imselectdt {
        width: 90%;
        padding: 2px;
        border: none;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .btnresetimpress {
        border: none;
        border-radius: 5px;
        width: 40px;
        font-size: 12px;
        background-color: #1F6E04;
        color: white;
    }
}

@media all and (max-width: 600px) {
    .searchTopBDS {
        top: 20%;
        width: 100%;
        right: 0%;
        
    }
    .inputsearchimpress {
        padding: 15px;
    }
    .inpsearchimpress {
        width: 70%;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
    }
    .btnsearchimpress {
        width: 20%;
        margin-left: 2%;
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 12px;
        height: 30px;
    }
    .selectgroup {
        display: flex;
        margin-top: 5px;
        width: 100%;
    }
    
    .selectimpress {
        width: 34%;
    }
    
    .imselectkv, .imselectgia, .imselectdt {
        width: 90%;
        padding: 5px;
        border: none;
        border-radius: 3px;
        font-size: 12px;
    }
    
    .btnresetimpress {
        border: none;
        border-radius: 5px;
        width: 40px;
        font-size: 12px;
        background-color: #1F6E04;
        color: white;
    }
}