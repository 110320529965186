.payContainer {
  width: 100%;
  position: absolute;  
  background-image: url(../pay/bgpay.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.payment {
    margin-top: 8%;
    font-family: Arial, sans-serif;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-image: url(../../components/featured/img/bannwe3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 90vh;
    margin-bottom: 5%;
    margin-left: 27%;
  }

  .payment:hover {
    transform: scale(1.05);
    transition: all .2s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border: 2px solid #1a8fff;
  }
  
  .titlePay {
    text-align: center;
    font-size: 28px;
    text-transform: uppercase;
    color: white;
    margin-bottom: 10px;
    font-weight: 600;
    background-color: rgb(33, 114, 229);
    border-radius: 10px;
  }

  .titlePay:hover {
    color: red;
    transform: scale(1.05);
    transition: all .2s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border: 2px solid green;
  }

  .payment h2 {
    margin-bottom: 22px;
    color: green;
   
  }
  
  .payment p {
    margin-bottom: 10px;
  }
  
  .payment input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .payment button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    border: none;
    border-radius: 4px;
  }
  
  .payment button:nth-child(1) {
      background-color: #1a8fff; /* Momo color */
      color: white; /* Text color */
  }
  
  .payment button:nth-child(2) {
      background-color: #fcbf49; /* VNPay color */
      color: white; /* Text color */
  }
  
  .payment button:hover {
      opacity: 0.9;
  }

  .titlepPayment {
    font-size: 24px;
    color: #1a8fff;
  }

  .userPay {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .p2 {
    font-size: 18px;
  }

  .infoPay {
    width: 100%;
    height: 30%;
    background-color: rgb(120, 243, 120);
    border-radius: 3px;
    padding-left: 20px;
    padding-top: 5px;
    box-shadow: 10px 10px 8px 8px pink;
    color: red;
    text-transform: uppercase;
  }

  .infoPay:hover {
    transform: scale(1.05);
    transition: all .2s ease-in-out;
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border: 2px solid red;
    color: yellow;
  }

  .imgpay {
    width: 40px;
    height: 15px;
    margin-right: 20px;
  }

  .imgvnpay {
    width: 65px;
    height: 15px;
    margin-right: 20px;
  }

  .payPay button{
    text-transform: uppercase;
  }

  @media all and (max-width: 360px) {
    .payment {
        margin-top: 24%;
        font-family: Arial, sans-serif;
        max-width: 600px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;
        margin-bottom: 5%;
        margin-left: 0%;
      }
    
      .payment:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid #1a8fff;
      }
      
      .titlePay {
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 10px;
        font-weight: 600;
        background-color: rgb(33, 114, 229);
        border-radius: 10px;
      }
    
      .titlePay:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
      }
    
      .payment h2 {
        margin-bottom: 22px;
        color: green;
      }
      
      .payment p {
        margin-bottom: 10px;
      }
      
      .payment input {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      
      .payment button {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        border: none;
        border-radius: 4px;
      }
      
      .payment button:nth-child(1) {
          background-color: #1a8fff; /* Momo color */
          color: white; /* Text color */
      }
      
      .payment button:nth-child(2) {
          background-color: #fcbf49; /* VNPay color */
          color: white; /* Text color */
      }
      
      .payment button:hover {
          opacity: 0.9;
      }
    
      .titlepPayment {
        font-size: 18px;
        color: #1a8fff;
      }
    
      .userPay {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
      .p2 {
        font-size: 16px;
      }
    
      .infoPay {
        width: 100%;
        height: 30%;
        background-color: rgb(120, 243, 120);
        border-radius: 3px;
        padding-left: 20px;
        padding-top: 5px;
        box-shadow: 10px 10px 8px 8px pink;
        color: red;
        text-transform: uppercase;
      }
    
      .infoPay:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid red;
        color: yellow;
      }
    
      .imgpay {
        width: 40px;
        height: 15px;
        margin-right: 20px;
      }
    
      .imgvnpay {
        width: 65px;
        height: 15px;
        margin-right: 20px;
      }
    
      .payPay button{
        text-transform: uppercase;
      }
  }

  @media all and (max-width: 375px) {
    .payment {
        margin-top: 24%;
        font-family: Arial, sans-serif;
        max-width: 600px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;
        margin-bottom: 5%;
        margin-left: 0%;
      }
    
      .payment:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid #1a8fff;
      }
      
      .titlePay {
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 10px;
        font-weight: 600;
        background-color: rgb(33, 114, 229);
        border-radius: 10px;
      }
    
      .titlePay:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
      }
    
      .payment h2 {
        margin-bottom: 22px;
        color: green;
      }
      
      .payment p {
        margin-bottom: 10px;
      }
      
      .payment input {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      
      .payment button {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        border: none;
        border-radius: 4px;
      }
      
      .payment button:nth-child(1) {
          background-color: #1a8fff; /* Momo color */
          color: white; /* Text color */
      }
      
      .payment button:nth-child(2) {
          background-color: #fcbf49; /* VNPay color */
          color: white; /* Text color */
      }
      
      .payment button:hover {
          opacity: 0.9;
      }
    
      .titlepPayment {
        font-size: 18px;
        color: #1a8fff;
      }
    
      .userPay {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
      .p2 {
        font-size: 16px;
      }
    
      .infoPay {
        width: 100%;
        height: 30%;
        background-color: rgb(120, 243, 120);
        border-radius: 3px;
        padding-left: 20px;
        padding-top: 5px;
        box-shadow: 10px 10px 8px 8px pink;
        color: red;
        text-transform: uppercase;
      }
    
      .infoPay:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid red;
        color: yellow;
      }
    
      .imgpay {
        width: 40px;
        height: 15px;
        margin-right: 20px;
      }
    
      .imgvnpay {
        width: 65px;
        height: 15px;
        margin-right: 20px;
      }
    
      .payPay button{
        text-transform: uppercase;
      }
  }

  @media all and (max-width: 414px) {
    .payment {
        margin-top: 24%;
        font-family: Arial, sans-serif;
        max-width: 600px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;
        margin-bottom: 5%;
        margin-left: 0%;
      }
    
      .payment:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid #1a8fff;
      }
      
      .titlePay {
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 10px;
        font-weight: 600;
        background-color: rgb(33, 114, 229);
        border-radius: 10px;
      }
    
      .titlePay:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
      }
    
      .payment h2 {
        margin-bottom: 22px;
        color: green;
      }
      
      .payment p {
        margin-bottom: 10px;
      }
      
      .payment input {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      
      .payment button {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        border: none;
        border-radius: 4px;
      }
      
      .payment button:nth-child(1) {
          background-color: #1a8fff; /* Momo color */
          color: white; /* Text color */
      }
      
      .payment button:nth-child(2) {
          background-color: #fcbf49; /* VNPay color */
          color: white; /* Text color */
      }
      
      .payment button:hover {
          opacity: 0.9;
      }
    
      .titlepPayment {
        font-size: 18px;
        color: #1a8fff;
      }
    
      .userPay {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
      .p2 {
        font-size: 16px;
      }
    
      .infoPay {
        width: 100%;
        height: 30%;
        background-color: rgb(120, 243, 120);
        border-radius: 3px;
        padding-left: 20px;
        padding-top: 5px;
        box-shadow: 10px 10px 8px 8px pink;
        color: red;
        text-transform: uppercase;
      }
    
      .infoPay:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid red;
        color: yellow;
      }
    
      .imgpay {
        width: 40px;
        height: 15px;
        margin-right: 20px;
      }
    
      .imgvnpay {
        width: 65px;
        height: 15px;
        margin-right: 20px;
      }
    
      .payPay button{
        text-transform: uppercase;
      }
  }

  @media all and (max-width: 600px) {
    .payment {
        margin-top: 24%;
        font-family: Arial, sans-serif;
        max-width: 600px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;
        margin-bottom: 5%;
        margin-left: 0%;
      }
    
      .payment:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid #1a8fff;
      }
      
      .titlePay {
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 10px;
        font-weight: 600;
        background-color: rgb(33, 114, 229);
        border-radius: 10px;
      }
    
      .titlePay:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
      }
    
      .payment h2 {
        margin-bottom: 22px;
        color: green;
      }
      
      .payment p {
        margin-bottom: 10px;
      }
      
      .payment input {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }
      
      .payment button {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        border: none;
        border-radius: 4px;
      }
      
      .payment button:nth-child(1) {
          background-color: #1a8fff; /* Momo color */
          color: white; /* Text color */
      }
      
      .payment button:nth-child(2) {
          background-color: #fcbf49; /* VNPay color */
          color: white; /* Text color */
      }
      
      .payment button:hover {
          opacity: 0.9;
      }
    
      .titlepPayment {
        font-size: 18px;
        color: #1a8fff;
      }
    
      .userPay {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
      .p2 {
        font-size: 16px;
      }
    
      .infoPay {
        width: 100%;
        height: 30%;
        background-color: rgb(120, 243, 120);
        border-radius: 3px;
        padding-left: 20px;
        padding-top: 5px;
        box-shadow: 10px 10px 8px 8px pink;
        color: red;
        text-transform: uppercase;
      }
    
      .infoPay:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid red;
        color: yellow;
      }
    
      .imgpay {
        width: 40px;
        height: 15px;
        margin-right: 20px;
      }
    
      .imgvnpay {
        width: 65px;
        height: 15px;
        margin-right: 20px;
      }
    
      .payPay button{
        text-transform: uppercase;
      }
  }

  @media all and (max-width: 800px) {
    .payment {
        margin-top: 24%;
        font-family: Arial, sans-serif;
        max-width: 600px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-image: url(../../components/featured/img/bannwe3.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 80vh;
        margin-bottom: 5%;
        margin-left: 0%;
      }
    
      .payment:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid #1a8fff;
      }
      
      .titlePay {
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
        color: white;
        margin-bottom: 10px;
        font-weight: 600;
        background-color: rgb(33, 114, 229);
        border-radius: 10px;
      }
    
      .titlePay:hover {
        color: red;
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid green;
      }
    
      .payment h2 {
        margin-bottom: 22px;
        color: green;
      }
      
      .payment p {
        margin-bottom: 10px;
      }
      
      .payment input {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 16px;
      }
      
      .payment button {
        display: block;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
      }
      
      .payment button:nth-child(1) {
          background-color: #1a8fff; /* Momo color */
          color: white; /* Text color */
      }
      
      .payment button:nth-child(2) {
          background-color: #fcbf49; /* VNPay color */
          color: white; /* Text color */
      }
      
      .payment button:hover {
          opacity: 0.9;
      }
    
      .titlepPayment {
        font-size: 20px;
        color: #1a8fff;
      }
    
      .userPay {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
      .p2 {
        font-size: 18px;
      }
    
      .infoPay {
        width: 100%;
        height: 30%;
        background-color: rgb(120, 243, 120);
        border-radius: 3px;
        padding-left: 20px;
        padding-top: 5px;
        box-shadow: 10px 10px 8px 8px pink;
        color: red;
        text-transform: uppercase;
      }
    
      .infoPay:hover {
        transform: scale(1.05);
        transition: all .2s ease-in-out;
        box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
        border: 2px solid red;
        color: yellow;
      }
    
      .imgpay {
        width: 40px;
        height: 15px;
        margin-right: 20px;
      }
    
      .imgvnpay {
        width: 65px;
        height: 15px;
        margin-right: 20px;
      }
    
      .payPay button{
        text-transform: uppercase;
      }
  }