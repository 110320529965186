.footer {
    border: 1px solid #1F6E04;
    display: flex;
    width: 100%;
    background-image: url(./img/abstract-luxury-gold-yellow-gradient-studio-wall-well-use-as-background-layout-banner-product-presentation.jpg);
    background-size: cover;
}

.fList {
    height: 100%;
    width: 420px;
    padding-left: 10px;
}

.logoFooter {
    height: 250px;
    width: 300px;
}

.footerTitle {
    margin-top: 35px;
    font-size: 28px;
    font-weight: 600;
}

.iconFooter1 {
    display: flex;
    margin-top: 25px;
}

.icon {
   margin-left: 15px;
   margin-right: 15px;
   padding-top: 2px;
   width: 32px;
   height: 32px;
   color: #1F6E04;
}

.iconf {
    margin-left: 15px;
    margin-right: 15px;
    width: 28px;
    height: 28px;
    color: #1F6E04;
}

.iconz {
    margin-left: 15px;
    margin-right: 15px;
    width: 28px;
    height: 28px;
    color: #1F6E04;
}

.sList {
    height: 100%;
    width: 420px;

}

.tList {
    height: 100%;
    width: 420px;
    padding-left: 10px;
}


.h4footer {
    font-size: 14px;
    opacity: 90%;
}

.hotline {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 35px;
    color: white;
    animation: colorbg 0.5s infinite;
         -webkit-animation: colorbg 0.5s infinite;
         -moz-animation: colorbg 0.5s infinite;
         -o-animation: colorbg 0.5s infinite
}

.titlehotline {
    background-color: #1F6E04;
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 7px;
    padding-left: 7px;
}

.iconPhone {
    padding-left: 15px;
    width: 22px;
    height: 28px;
}

.chfooter {
    display: flex;
    align-items:center ;
    justify-content: left;
    line-height: 0.7;
    font-size: 18px;
    box-sizing: border-box;
}

.title1 {
    margin-top: 22px;
    font-size: 26px;
    margin-right: 10px;
    font-weight: 400;
}

.pfooter {
    margin-top: 15px;
    line-height: 1.1;
    text-align: justify;
}

.p1footer {
    font-size: 14px;
    opacity: 90%;
}

.icon1 {
    width: 25px;
    height: 25px;
    padding-right: 10px;
    
}

.h2footer {
    margin-top: 25px;
    font-size: 26px;
    font-weight: 400;
    
}

.formfooter {
    margin-top: 34px;
}

.ipform {
    width: 100%;
    margin-bottom: 22px;
}

.btnDk {
    margin-left: 150px;
}

.buttonDk {
    border: none;
    border-radius: 4px;
    background-color: #1F6E04;
    color: white;
    width: 100px;
    height: 40px;
}

.buttonDk:hover {
    opacity: 0.6;
}

.textft {
    margin-top: 10px;
    line-height: 10px;
    font-size: 14px;
}

.pngang {
    border-bottom: 1px solid rgb(96, 81, 81);
    width: 100%;
    margin-top: 27px;

}

.pngang2 {
    border-bottom: 1px solid rgb(126, 110, 110);
    width: 100%;
   
}

.pngang3 {
    border-bottom: 1px solid rgb(96, 81, 81);
    width: 100%;
    margin-top: 10px;
}

.pngang4 {
    border-bottom: 1px solid rgb(96, 81, 81);
    width: 100%;
    margin-top: 23px;
}

@keyframes colorbg {
    0% {
        color: white;
    }
    50% {
        color: yellow;
    }
}

.errorform {
    color: red;
}

/* Dành cho máy tính bảng*/
@media all and (max-width: 1024px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .logoFooter {
        width: 220px;
        height: 200px;
    }
    .titlehotline {
        font-size: 18px;
    }
    .iconPhone {
        width: 20px;
        height: 20px;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .ipform {
        width: 100%;
    }

    .btnDk {
        margin-right: 15px;
    }
    .buttonDk {
        font-size: 14px;
       margin-right: 15px;
    }
    .pngang4 {
        margin-top: 61px;
    }
    .pngang {
        margin-top: 67px;
    }
}


/* Dành cho máy tính bảng chiều ngang */
@media all and (max-width: 800px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .logoFooter {
        width: 200px;
        height: 200px;
    }
    .titlehotline {
        font-size: 18px;
    }
    .iconPhone {
        width: 20px;
        height: 20px;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .ipform {
        width: 100%;
    }

    .btnDk {
        margin-right: 15px;
    }
    .buttonDk {
        font-size: 13px;
        width: 90%;
    }
    .pngang4 {
        margin-top: 56px;
    }
    .pngang {
        margin-top: 47px;
    }
    .title1 {
        font-size: 23px;
    }
    .footerTitle {
        font-size: 25px;
    }
    .h2footer {
        font-size: 23px;
    }
    .h4footer {
        font-size: 21px;
    }
    .pfooter {
        font-size: 16px;
    }
}

/* Dành cho máy tính bảng loại lớn*/
@media all and (max-width: 768px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .logoFooter {
        width: 200px;
        height: 200px;
    }
    .titlehotline {
        font-size: 18px;
    }
    .iconPhone {
        width: 20px;
        height: 20px;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .ipform {
        width: 100%;
    }

    .btnDk {
        margin-right: 15px;
       
    }
    .buttonDk {
        font-size: 13px;
        width: 90%;
    }
    .pngang4 {
        margin-top: 55px;
    }
    .pngang {
        margin-top: 61px;
    }
    .title1 {
        font-size: 23px;
    }
    .footerTitle {
        font-size: 25px;
    }
    .h2footer {
        font-size: 23px;
    }
    .h4footer {
        font-size: 21px;
    }
    .pfooter {
        font-size: 16px;
    }
}

/* Dành cho máy tính bảng chiều dọc*/
@media all and (max-width: 600px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .logoFooter {
        width: 150px;
        height: 130px;
    }
    .titlehotline {
        font-size: 14px;
    }
    .iconPhone {
        width: 20px;
        height: 20px;
        display: block;
        margin-left: 40px;
        margin-top: 10px;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .ipform {
        width: 100%;
        font-size: 80%;
    }
    .btnDk {
        margin-left: 30%; 
    }
    .buttonDk {
        width: 60%;
        font-size: 70%;
    }
    .pngang4 {
        margin-top: 45px;
    }
    .pngang {
        margin-top: 31px;
    }
    .title1 {
        font-size: 18px;
    }
    .footerTitle {
        font-size: 20px;
    }
    .h2footer {
        font-size: 18px;
    }
    .h4footer {
        font-size: 16px;
    }
    .pfooter {
        font-size: 13px;
        margin-top: 10px;
        line-height: 1.1;
    }

    .iconFooter1 {
      display: block;
      width: 10%;
      height: 20%;
      
    }
    .icon {
       display: block;
       margin-bottom: 10px;
    }
    .icon1 {
        width: 20px;
        height: 20px;
    }
    .textft {
        font-size: 12px;
    }
    .formfooter {
        margin-bottom: 10px;
        margin-top: 14%;
    }
}

/* Dành cho điện thoại chiều ngang */
@media all and (max-width: 480px) {
    .footer {
        display: block;
    }
    .fList {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
    }
    .logoFooter {
        width: 120px;
        height: 110px;
    }
    .footerTitle {
        margin-top: 1%;
        margin-left: 10px;
        font-size: 90%;
        width: 10%;
    }
    .hotline {
      width: 1%;
      margin-left: 32%;
      margin-top: 0%;
    }
    .titlehotline {
        font-size: 80%;
    }
    .iconPhone {
        width: 20px;
        height: 20px;
        margin-left: 20%;
    }
    .iconFooter1 {
        margin-top: 10%;
        display: flex;
    }
    .sList {
        width: 100%;
        padding-left: 1%;
    }
    .tList {
        width: 100%;
        padding-left: 1%;
    }
    .title1 {
        margin-top: 0%;
    }
    .chfooter {
        margin-top: 0%;
        margin-bottom: 0%;
    }
    .h2footer {
        margin-top: 0%;
        margin-bottom: 0%;
    }
    .formfooter {
        margin-top: 0%;
    }
    .pngang4 {
        margin-top: 3%;
    }
    .pngang {
       display: none;
    }
    .pngang2 {
        display: none;
    }
    .buttonDk {
        width: 40%;
        font-size: 70%;
        height: 30px;
        margin-bottom: 10px;
    }
    .ipform {
        width: 100%;
    }
}



@media all and (max-width: 414px) {
    .footer {
        display: block;
        width: 100%;
    }
    .fList {
        display: flex;
        width: 100%;
    }
    .logoFooter {
        width: 100px;
        height: 90px;
    }
    .footerTitle {
        margin-top: 5%;
        margin-left: 10px;
        font-size: 90%;
    }
    .hotline {
       margin-left: 10%;
       margin-top: 0%;
    }
    .titlehotline {
        font-size: 60%;
    }
    .iconPhone {
        margin-left: 6%;
        width: 30%;
        height: 30%;
    }
    .iconFooter1 {
        margin-top: 2%;
        display: block;
        margin-left: 30px;
    }
    .sList {
        width: 100%;
        padding-left: 1%;
    }
    .tList {
        width: 100%;
        padding-left: 1%; 
    }
    .pngang4 {
        margin-top: 3%;
    }
    .pngang {
       display: none;
    }
    .pngang2 {
        display: none;
    }
    .buttonDk {
        width: 40%;
        font-size: 70%;
        height: 30px;
        margin-bottom: 10px;
    }
    .ipform {
        width: 100%;
    }
}


/* Dành cho điện thoại chiều ngang */
@media all and (max-width: 360px) {
    .footer {
        display: block;
        width: 100%;
    }
    .fList {
        display: flex;
        width: 100%;
    }
    .logoFooter {
        width: 100px;
        height: 90px;
    }
    .footerTitle {
        margin-top: 5%;
        margin-left: 10px;
        font-size: 90%;
    }
    .hotline {
       margin-left: 10%;
       margin-top: 0%;
    }
    .titlehotline {
        font-size: 60%;
    }
    .iconPhone {
        margin-left: 6%;
        width: 30%;
        height: 30%;
    }
    .iconFooter1 {
        margin-top: 2%;
        display: block;
        margin-left: 30px;
    }
    .sList {
        width: 100%;
        padding-left: 1%;
    }
    .tList {
        width: 100%;
        padding-left: 1%; 
    }
    .pngang4 {
        margin-top: 3%;
    }
    .pngang {
       display: none;
    }
    .pngang2 {
        display: none;
    }
    .buttonDk {
        width: 40%;
        font-size: 70%;
        height: 30px;
        margin-bottom: 10px;
    }
    .ipform {
        width: 100%;
    }
}

/* Dành cho điện thoại */
@media all and (max-width: 320px) {
    .footer {
        display: block;
        width: 100%;
    }
    .fList {
        display: flex;
        width: 100%;
    }
    .logoFooter {
        width: 100px;
        height: 90px;
    }
    .footerTitle {
        margin-top: 5%;
        margin-left: 10px;
        font-size: 90%;
    }
    .hotline {
       margin-left: 10%;
       margin-top: 0%;
    }
    .titlehotline {
        font-size: 60%;
    }
    .iconPhone {
        margin-left: 6%;
        width: 30%;
        height: 30%;
    }
    .iconFooter1 {
        margin-top: 2%;
        display: block;
        margin-left: 20px;
    }
    .sList {
        width: 100%;
        padding-left: 1%;
    }
    .tList {
        width: 100%;
        padding-left: 1%; 
    }
    .pngang4 {
        margin-top: 3%;
    }
    .pngang {
       display: none;
    }
    .pngang2 {
        display: none;
    }
    .buttonDk {
        width: 40%;
        font-size: 70%;
        height: 30px;
        margin-bottom: 10px;
    }
    .ipform {
        width: 100%;
    }
}

@media all and (min-width: 1280px) and (max-width: 1366px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
}

@media all and (min-width: 1366px) and (max-width: 1440px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .pngang {
        margin-top: 27.5px;
    }
    .pngang4 {
        margin-top: 22.5px;
    }
}

@media all and (min-width: 1440px) and (max-width: 1680px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .pngang {
        margin-top: 27.5px;
    }
    .pngang4 {
        margin-top: 54px;
    }
    .ipform {
        width: 100%;
    }
    .btnDk {
        margin-left: 40%;
    }
}

@media all and (min-width: 1680px) and (max-width: 1920px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .pngang {
        margin-top: 27.5px;
    }
    .pngang4 {
        margin-top: 54px;
    }
    .ipform {
        width: 100%;
    }
    .btnDk {
        margin-left: 45%;
    }
}

@media all and (min-width: 1920px) and (max-width: 2560px) {
    .footer {
        width: 100%;
    }
    .fList {
        width: 33%;
    }
    .sList {
        width: 33%;
    }
    .tList {
        width: 33%;
    }
    .pngang {
        margin-top: 27.5px;
    }
    .pngang4 {
        margin-top: 54px;
    }
    .ipform {
        width: 100%;
    }
    .btnDk {
        margin-left: 45%;
    }
}
