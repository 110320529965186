.featured {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 5%;
    position: relative;
}

.imgFeatured {
    width: 100%;
    height: 600px;
}

.featuredContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 400px;
}

.featuredItem {
   width: 50%;
   height: 100%;
}

.titleFeatured {
    font-size: 25px;
    color: #DAA520;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 15px;
}

.textFeatured {
    font-weight: 350;
    margin-left: 10px;
    margin-right: 20px;
    text-align: justify;
    font-size: 16px;
}

.featuredBtn {
    margin-left: 80%;
    border: 1px solid #1F6E04;
    border-radius: 10%;
    background-color:#F5F6CE ;
    font-weight: 400;
    color: #1F6E04;
    padding: 5px;
}

.iconFeatured {
    width: 80px;
    height: 80px;
    margin-left: 80px;
    margin-right: 80px;
    border-radius: 50%;
    border: solid 2px black;
}

.featureItem1 {
  background-color: #F5F6CE;
  width: 100%;
  height: 110%;
  padding-top: 20px;
}

.featureItem2 {
    width: 100%;
    height: 110%;
    background-image: url(./img/bannwe3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5px;
  }

.infoFeatured {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.doubleIcon1 {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doubleIcon2 {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featuredBtn1 {
    margin-top: 20px;
    margin-left: 250px;
    border: none;
    width: 150px;
    height: 40px;
    background-color: #1F6E04;
    color: white;
    border-radius: 8px;
}

.numberFeatured {
    font-size: 22px;
    font-weight: 600;
    color: #1F6E04;
}

.moreFeature {
    font-size: 22px;
    font-weight: 600;
    color: #1F6E04;
}

.linkfeature1 {
    text-decoration: none;
    color: #1F6E04;
}

.linkfeature {
    text-decoration: none;
    color: white;
}




/* Dành cho máy tính bảng*/
@media all and (max-width: 1024px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        height: 500px;
        width: 100%;
    }
    .featuredContainer {
        height: 360px;
        width: 100%;
    }
    .titleFeatured {
        font-size: 21px;
    }
    .textFeatured {
        font-size: 14px;
    }
    .featuredBtn {
        font-size: 14px;
    }
    .featureItem {
        width: 40%;
    }

    .featureItem2 {
        height: 370px;
    }
    .iconFeatured {
        width: 60px;
        height: 60px;
    }
    .numberFeatured {
        font-size: 20px;
    }
    .moreFeature {
        font-size: 20px;
    }
    .featuredBtn1 {
        margin-top: 20px;
        margin-left: 200px;
        border: none;
        width: 150px;
        height: 40px;
        background-color: #1F6E04;
        color: white;
        border-radius: 8px;
    }
}

/* Dành cho máy tính bảng chiều ngang */
@media all and (max-width: 800px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
        height: 450px;
    }
    .featuredContainer {
        width: 100%;
        height: 350px;
    }
    .titleFeatured {
        font-size: 18px;
    }
    .textFeatured {
        font-size: 12px;
        text-align: justify;
    }
    .featuredBtn {
        font-size: 14px;
    }
    .featuredItem {
        width: 50%;
    }
    .featureItem2 {
        height: 360px;
        width: 100%;
    }
    .featureItem1 {
        height: 360px;
        width: 100%;
    }
    .iconFeatured {
        width: 60px;
        height: 60px;
    }
    .numberFeatured {
        font-size: 18px;
    }
    .moreFeature {
        font-size: 17px;
    }
    .featuredBtn1 {
        margin-top: 40px;
        margin-left: 140px;
        border: none;
        width: 150px;
        height: 35px;
        background-color: #1F6E04;
        color: white;
        border-radius: 8px;
    }
    .linkfeature {
        font-size: 15px;
    }
}

/* Dành cho máy tính bảng loại lớn*/
@media all and (max-width: 768px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
        height: 350px;
    }
    .featuredContainer {
        height: 335px;
    }
    .titleFeatured {
        font-size: 16px;
    }
    .textFeatured {
        font-size: 11.5px;
        text-align: justify;
    }
    .featuredBtn {
        font-size: 12px;
    }
    .featuredItem {
        width: 50%;
    }
    .featureItem2 {
        height: 345px;
        width: 100%;
    }
    .featureItem1 {
        height: 345px;
        width: 100%;
    }
    .iconFeatured {
        width: 50px;
        height: 50px;
    }
    .numberFeatured {
        font-size: 16px;
    }
    .moreFeature {
        font-size: 15px;
    }
    .featuredBtn1 {
        margin-top: 55px;
        margin-left: 140px;
        border: none;
        width: 150px;
        height: 30px;
        background-color: #1F6E04;
        color: white;
        border-radius: 8px;
    }
    .linkfeature {
        font-size: 14px;
    }
}

/* Dành cho máy tính bảng chiều dọc*/
@media all and (max-width: 600px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
        height: 300px;
    }
    .featuredContainer {
        height: 285px;
    }
    .titleFeatured {
        font-size: 14px;
        margin-bottom: 2%;
    }
    .textFeatured {
        font-size: 9.5px;
        text-align: justify;
        margin-bottom: 2%;
    }
    .featuredBtn {
        font-size: 10px;
    }
    .featuredItem {
        width: 50%;
    }
    .featureItem2 {
        height: 345px;
        width: 100%;
    }
    .featureItem1 {
        height: 345px;
        width: 100%;
    }
    .iconFeatured {
        width: 40px;
        height: 40px;
        margin-left: 50px;
        margin-right: 50px;
    }
    .numberFeatured {
        font-size: 16px;
    }
    .moreFeature {
        font-size: 12px;
    }
    .featuredBtn1 {
        margin-top: 40px;
        margin-left: 90px;
        border: none;
        width: 120px;
        height: 30px;
        background-color: #1F6E04;
        color: white;
        border-radius: 8px;
    }
    .linkfeature {
        font-size: 12px;
    }
}

/* Dành cho điện thoại chiều ngang */
@media all and (max-width: 480px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
        height: 280px;
    }
    .featuredContainer {
        display: block;
        height: 500px;
    }

    .featuredItem {
        width: 100%;
        height: 50%;
    }

    .featureItem1 {
        padding-left: 3%;
        height: 100%;
    }
    .featureItem2 {
        display: block;
        height: 100%;
        padding-top: 0%;
    }
    .titleFeatured {
        font-size: 12px;
    }
    .textFeatured {
        font-size: 11px;
    }
    .iconFeatured {
        width: 40px;
        height: 40px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .doubleIcon1 {
        margin-top: 0%;
        padding-top: 2%;
    }
    .numberFeatured {
        font-size: 13px;
    }
    .moreFeature {
        font-size: 12px;
    }
    .featuredBtn1 {
        margin-left: 37%;
        margin-top: 5%;
        height: 30px;
        width: 120px;
    }
    .linkfeature {
        font-size: 11px;
    }
}

@media all and (max-width: 414px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
        height: 250px;
    }
    .featuredContainer {
        display: block;
        height: 620px;
    }

    .featuredItem {
        width: 100%;
        height: 50%;
    }

    .featureItem1 {
        padding-left: 0%;
        height: 100%;
    }
    .featureItem2 {
        display: block;
        height: 100%;
        padding-bottom: 0%;
    }
    .titleFeatured {
        font-size: 12px;
    }
    .textFeatured {
        font-size: 11px;
    }
    .iconFeatured {
        width: 40px;
        height: 40px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .doubleIcon1 {
        margin-top: 0%;
        padding-top: 4%;
    }
    .numberFeatured {
        font-size: 13px;
    }
    .moreFeature {
        font-size: 12px;
    }
    .featuredBtn1 {
        margin-left: 32%;
        margin-top: 12%;
        height: 30px;
        width: 120px;
        
    }
    .linkfeature {
        font-size: 11px;
    }
}


/* Dành cho điện thoại chiều ngang */
@media all and (max-width: 360px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
        height: 250px;
    }
    .featuredContainer {
        display: block;
        height: 620px;
    }

    .featuredItem {
        width: 100%;
        height: 50%;
    }

    .featureItem1 {
        padding-left: 0%;
        height: 100%;
    }
    .featureItem2 {
        display: block;
        height: 100%;
        padding-bottom: 0%;
    }
    .titleFeatured {
        font-size: 12px;
    }
    .textFeatured {
        font-size: 11px;
    }
    .iconFeatured {
        width: 40px;
        height: 40px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .doubleIcon1 {
        margin-top: 0%;
        padding-top: 4%;
    }
    .numberFeatured {
        font-size: 13px;
    }
    .moreFeature {
        font-size: 12px;
    }
    .featuredBtn1 {
        margin-left: 32%;
        margin-top: 12%;
        height: 30px;
        width: 120px;
        
    }
    .linkfeature {
        font-size: 11px;
    }
}

/* Dành cho điện thoại */
@media all and (max-width: 320px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
        height: 250px;
    }
    .featuredContainer {
        display: block;
        height: 600px;
    }

    .featuredItem {
        width: 100%;
        height: 50%;
    }

    .featureItem1 {
        padding-left: 0%;
        height: 100%;
    }
    .featureItem2 {
        display: block;
        height: 100%;
        padding-bottom: 0%;
    }
    .titleFeatured {
        font-size: 12px;
    }
    .textFeatured {
        font-size: 10px;
    }
    .iconFeatured {
        width: 40px;
        height: 40px;
        margin-left: 30px;
        margin-right: 30px;
    }
    .doubleIcon1 {
        margin-top: 0%;
        padding-top: 4%;
    }
    .numberFeatured {
        font-size: 13px;
    }
    .moreFeature {
        font-size: 12px;
    }
    .featuredBtn1 {
        margin-left: 30%;
        margin-top: 12%;
        height: 30px;
        width: 120px;
        
    }
    .linkfeature {
        font-size: 11px;
    }
}

@media all and (min-width: 1280px) and (max-width: 1366px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
    }
}

@media all and (min-width: 1366px) and (max-width: 1440px) {
    .featured {
        width: 100%;
    }
    .imgFeatured {
        width: 100%;
    }
}

@media all and (min-width: 1440px) and (max-width: 1680px) {
    .featured {
        width: 100%;
        margin-top: 4.5%;
    }
    .imgFeatured {
        width: 100%;
    }
}

@media all and (min-width: 1680px) and (max-width: 1920px) {
    .featured {
        width: 100%;
        margin-top: 4%;
    }
    .imgFeatured {
        width: 100%;
    }
}

@media all and (min-width: 1920px) and (max-width: 2560px) {
    .featured {
        width: 100%;
        margin-top: 3%;
    }
    .imgFeatured {
        width: 100%;
    }
}